import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";

export const mapApis = createApi({
  reducerPath: "map",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    getNearestPlaces: builder.mutation({
      query: ({ lat, lon,zoom,body }) => ({
        url: `api/v1/filter/nearby/${lat}/${lon}/${zoom}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetNearestPlacesMutation } = mapApis;
