import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";
import Cookies from "js-cookie";

export const backpackApis = createApi({
  reducerPath: "backPack",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      const token = Cookies.get("iran_token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Back_Pack_Category"],
  endpoints: (builder) => ({
    getAllBackPackCategories: builder.query({
      query: () => "/api/v1/profile/todoList",
      providesTags: ["Back_Pack_Category"],
    }),
    addNewBackPackCategory: builder.mutation({
      query: (data) => ({
        url: "/api/v1/profile/todoList/todoCustom/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    removeBackPackCategory: builder.mutation({
      query: (todoCustom_id) => ({
        url: `/api/v1/profile/todoList/todoCustom/destroy/${todoCustom_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    addNewBackPackItem: builder.mutation({
      query: (data) => ({
        url: "/api/v1/profile/todoList/todoCustom/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    renameBackPackItem: builder.mutation({
      query: ({ todo_id, newName }) => ({
        url: `/api/v1/profile/todoList/todoCustom/rename/${todo_id}?name=${newName}`,
        method: "POST",
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    deleteBackPackItem: builder.mutation({
      query: (todo_id) => ({
        url: `/api/v1/profile/todoList/todoCustom/destroy/${todo_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    submitCheckboxes: builder.mutation({
      query: (body) => ({
        url: "/api/v1/profile/todoList/todo/check",
        body,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useAddNewBackPackCategoryMutation,
  useGetAllBackPackCategoriesQuery,
  useDeleteBackPackItemMutation,
  useAddNewBackPackItemMutation,
  useRenameBackPackItemMutation,
  useSubmitCheckboxesMutation,
  useRemoveBackPackCategoryMutation,
} = backpackApis;
