import Request from "../Request";

export default class HomeRequest extends Request {
  /**
   *
   * @returns array of places list
   */
  static PlacesList() {
    return HomeRequest.sendRequest("home/place", "GET", {}, {});
  }

  /**
   *
   * @returns array of latest places
   */
  static LatestPlaces() {
    return HomeRequest.sendRequest("home/latest/place", "GET", {}, {});
  }

  /**
   *
   * @returns array of best places
   */
  static BestPlaces() {
    return HomeRequest.sendRequest("home/best/place", "GET", {}, {});
  }

  /**
   *
   * @returns array of last view places
   */
  static LastView() {
    return HomeRequest.sendRequest("home/lastView/place", "GET", {}, {});
  }

  /**
   *
   * @returns array of most view places
   */
  static MostView() {
    return HomeRequest.sendRequest("home/mostView/place", "GET", {}, {});
  }

  /**
   *
   * @returns list of latest posts in home page
   */
  static LatestPosts() {
    return HomeRequest.sendRequest("home/latest/post", "GET", {}, {});
  }

  /**
   *
   * @returns list of latest user seen places
   */
  static LastSeen() {
    return HomeRequest.sendRequest("home/getHistory", "GET", {}, {});
  }

  /**
   *
   * @param {number} place_id id of place which want to get details
   * @returns place details
   */
  static GetPlace(place_id) {
    return HomeRequest.sendRequest(
      `home/place/show/${place_id}`,
      "GET",
      {},
      {}
    );
  }

  /**
   *
   * @returns array of faq categories list
   */
  static FAQList() {
    return HomeRequest.sendRequest("home/faq", "GET", {}, {});
  }

  /**
   *
   * @param {number} category_id id of faq category which want to get questions and awnsers
   * @returns
   */
  static GetFAQ(category_id) {
    return HomeRequest.sendRequest(`home/faq/${category_id}`, "GET", {}, {});
  }

  /**
   *
   * @returns array of activites list
   */
  static Activities() {
    return HomeRequest.sendRequest("home/activity", "GET", {}, {});
  }

  /**
   *
   * @param {number} activity_id id of activity whcih want to get activite subsets
   * @returns
   */
  static GetActivity(activity_id) {
    return HomeRequest.sendRequest(
      `home/activity/show/${activity_id}`,
      "GET",
      {},
      {}
    );
  }

  /**
   *
   * @returns array of place types list
   */
  static PlacesTypes() {
    return HomeRequest.sendRequest("home/types", "GET", {}, {});
  }

  /**
   *
   * @param {string} params type , provice, search and etc.
   * @returns filtered place list
   */
  static FilterPlace(params, data) {
    return HomeRequest.sendRequest(`home/filter?${params}&page_count=10`);
  }

  /**
   *
   * @returns all provinces
   */
  static GetProvices() {
    return this.sendRequest("filter/getProvince", "POST", {}, {});
  }

  /**
   *
   * @returns all cities
   */
  static GetCities() {
    return this.sendRequest("filter/getCity", "POST", {}, {});
  }

  /**
   *
   * @param {string} search search params text
   * @returns array of searched places
   */
  static SearchPlaces(search) {
    return this.sendRequest(`filter/search?search=${search}`, "GET", {}, {});
  }

  /**
   *
   * @returns array of user history list
   */
  static UserSearchHistory() {
    return this.sendRequest("filter/showHistory", "GET", {}, {});
  }

  /**
   * 
   * @param {number} id id of user history item which want to delete from history
   * @returns history item deleted
   */
  static DeleteUserSearchHistory(id) {
    return this.sendRequest(
      `filter/deleteHistory/${id}`,
      "GET",
      {},
      {}
    );
  }

  /**
   *
   * @returns list of home sliders
   */
  static HomeSliders() {
    return this.sendRequest("home/slider", "GET", {}, {});
  }

  /**
   *
   * @param {object} data object of phone, email ,description & etc.
   * @returns message send
   */
  static ContactUs(data) {
    return this.sendRequest("home/contact", "POST", { data }, {});
  }

  /**
   *
   * @returns all sub activites
   */
  static GetSubActivites() {
    return this.sendRequest("home/subActivity", "GET", {}, {});
  }

  /**
   *
   * @param {object} data email
   * @returns email submited
   */
  static SubmitNewsletters(data) {
    return this.sendRequest("home/Newsletters", "POST", { data }, {});
  }
}
