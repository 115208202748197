import axios from "axios";
import {toast} from "react-hot-toast";
import Cookies from "js-cookie";
export default class Request {
  static async sendRequest(url, method, config = {}, utils = undefined) {
    const base_url = "https://api.irangardi.app/api/v1/";
    const token = Cookies.get("iran_token");

    const request = axios.request({
      url: base_url + url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      },
      ...config,
    });

    return Request.handleRequest(request, utils);
  }

  static async handleRequest(request, utils = undefined) {
    try {
      const response = await request;

      if (utils?.toastSuccess) {
        toast.success(utils.message);
      }
      return response;
    } catch (err) {
      if (err.response.status === 401) {
        return toast.error("نام کاربری یا کلمه عبور اشتباه می باشد");
      }
      toast.error(err.response.data.message);
    }
  }
}
