import React from "react";
import Cookies from "js-cookie";
// React Router DOM
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  return Cookies.get("iran_token") ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
