import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";
import Cookies from "js-cookie";

export const profileApis = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      const token = Cookies.get("iran_token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Activites"],
  endpoints: (builder) => ({
    getUserActivites: builder.query({
      query: () => "/api/v1/profile/activity",
      providesTags: ["Activites"],
    }),
    getAllActivites: builder.query({
      query: () => "/api/v1/profile/get/activity",
      providesTags: ["Activites"],
    }),
    addActivityToUserFavourite: builder.mutation({
      query: (activity_id) => ({
        url: `/api/v1/profile/store/activity/${activity_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Activites"],
    }),
    deleteActivityFromUserFavourite: builder.mutation({
      query: (activity_id) => ({
        url: `/api/v1/profile/destroy/activity/${activity_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Activites"],
    }),
  }),
});

export const {
  useGetUserActivitesQuery,
  useGetAllActivitesQuery,
  useAddActivityToUserFavouriteMutation,
  useDeleteActivityFromUserFavouriteMutation,
} = profileApis;
