import Request from "../Request";

export default class AuthRequest extends Request {
  /**
   *
   * @param {object} data mobile field.
   * @returns activate code send .
   */
  static Regsiter(data) {
    return AuthRequest.sendRequest(
      "user/signup",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "کد تایید ارسال شد",
      }
    );
  }

  /**
   *
   * @param {object} data object of first_name , last_name , username , password , c_password, etc.
   * @returns user info store
   */
  static RegsiterInfo(data) {
    return this.sendRequest(
      "profile/register/store",
      "POST",
      { data },
      { toastSuccess: true, message: "اطلاعات با موفقیت ثبت شد" }
    );
  }

  /**
   *
   * @param {object} data username & password
   * @returns user log in.
   */
  static Login(data) {
    return AuthRequest.sendRequest("user/login", "POST", { data }, {});
  }

  /**
   *
   * @returns user logout successfully
   */
  static Logout() {
    return AuthRequest.sendRequest("user/logout", "POST", {}, {});
  }

  /**
   *
   * @param {object} data mini code
   * @returns user activate
   */
  static Activation(data) {
    return AuthRequest.sendRequest(
      "user/active/sms",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "موفق بود",
      }
    );
  }

  /**
   *
   * @param {object} data mobile or email
   * @returns code send
   */
  static ForgetPassword(data) {
    return AuthRequest.sendRequest(
      "user/password/forget/sms",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "کد تایید ارسال شد",
      }
    );
  }

  static ForgetPasswordActivation(data) {
    return AuthRequest.sendRequest(
      "user/password/reset/sms",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "کد ارسال شد",
      }
    );
  }

  static ChangePassword(data) {
    return AuthRequest.sendRequest(
      "user/password/change",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "تغییر پسورد موفقیت آمیز بود",
      }
    );
  }

  /**
   *
   * @param {object} data username or mobile or email
   * @returns check user is exist or not
   */
  static UserExist(data) {
    return this.sendRequest("user/checkUser", "POST", { data }, {});
  }
}
