import Request from "../Request";

export class ProfileRequest extends Request {

    /**
     * 
     * @returns array of comments list
     */

  static MyCommentsList() {
    return this.sendRequest("profile/myComment", "GET", {}, {});
  }
}
