import Request from "../Request";

export class BlogRequest extends Request {
  /**
   *
   * @returns array of blog categories list
   */

  static GetBlogCategories() {
    return this.sendRequest("home/category", "GET", {}, {});
  }

  /**
   *
   * @returns array of blogs list
   */
  static GetBlogsList(page = 1, post_id, category_id) {
    return this.sendRequest(
      `home/post?page=${page}${post_id ? `&post_id=${post_id}` : ""}${
        category_id ? `&category_id=${category_id}` : ""
      }`,
      "GET",
      {},
      {}
    );
  }
}
