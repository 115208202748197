import Request from "../Request";

export default class UserRequest extends Request {
  /**
   *
   * @returns get user profile information such as first_name last_name ,mobile email and etc.
   */
  static GetProfile() {
    return UserRequest.sendRequest("profile/get", "GET", {}, {});
  }

  /**
   *
   * @param {object} data first_name,last_name,email,city,province & etc.
   * @returns user profile updated
   */
  static UpdateProfile(data) {
    return UserRequest.sendRequest(
      "profile/update",
      "POST",
      { data },
      { toastSuccess: true, message: "اطلاعات با موفقیت بروز شد" }
    );
  }

  /**
   *
   * @param {object} data object photo key
   * @returns profile avatar uploaded
   */
  static PorfileAvatar(data) {
    return UserRequest.sendRequest("profile/photo", "POST", { data }, {});
  }

  /**
   *
   * @param {number} place_id id of place which want to give rate
   * @param {number} rate rate value 1 to 5
   * @returns place was rated
   */
  static PlaceRating(place_id, rate) {
    return this.sendRequest(
      `profile/place/rate/${place_id}/${rate}`,
      "POST",
      {},
      {}
    );
  }

  static ProfileChagnePassword(data) {
    return UserRequest.sendRequest(
      "user/password/change",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "",
      }
    );
  }

  /**
   *
   * @returns get user favorite activites
   */
  static GetUserActivity() {
    return UserRequest.sendRequest("profile/activity", "GET", {}, {});
  }

  /**
   *
   * @returns get list of all exiting activites
   */
  static GetAllActivity() {
    return UserRequest.sendRequest("profile/get/activity", "GET", {}, {});
  }

  /**
   *
   * @param {number} id id of activity which want to add to user activity list
   * @returns activity added to user fav activites
   */
  static AddUserActivity(id) {
    return UserRequest.sendRequest(
      `profile/store/activity/${[id]}`,
      "POST",
      {},
      {}
    );
  }

  /**
   *
   * @param {number} id id of activity which want to remove from user favorite activites
   * @returns activity remove from user activites list
   */
  static DeleteUserActivity(id) {
    return UserRequest.sendRequest(
      `profile/destroy/activity/${id}`,
      "DELETE",
      {},
      {
        toastSuccess: true,
        message: "",
      }
    );
  }

  /**
   *
   * @returns user bookmark,favorites and customs place collection list
   */
  static GetCollection() {
    return UserRequest.sendRequest("profile/collection", "GET", {}, {});
  }

  /**
   *
   * @returns get array of post collection list
   */
  static GetPostCollections() {
    return this.sendRequest("profile/postCollection", "GET", {}, {});
  }

  /**
   *
   * @param {string} type post collection type , bookmark and favorite
   * @param {number} post_id id of post which want to add to collection list
   * @returns post added to collection list
   */
  static AddPostCollection(type, post_id) {
    return this.sendRequest(
      `profile/postCollection/store/${type}/${post_id}`,
      "POST",
      {},
      {}
    );
  }

  /**
   *
   * @param {string} type favurite or bookmark
   * @param {number} id id of place which want to add to collection
   * @returns place added to collection
   */
  static AddCollection(type, id) {
    return UserRequest.sendRequest(
      `profile/collection/store/${type}/${id}`,
      "POST",
      {},
      {}
    );
  }

  /**
   *
   * @param {object} data name , cu_parent and is_public => 0 or 1 and etc.
   * @returns parent custom collection created or place added to custom collection
   */
  static CreateCustomCollection(data) {
    return UserRequest.sendRequest(
      `profile/collection/storeCustom`,
      "POST",
      { data },
      {}
    );
  }

  /**
   *
   * @param {number} id id of collection parent or place which want to remove
   * @returns collection removed
   */
  static RemoveCustomCollection(id) {
    return UserRequest.sendRequest(
      `profile/collection/destroyCustom/${id}`,
      "GET",
      {},
      {}
    );
  }

  static RemoveCollection(id) {
    return UserRequest.sendRequest(
      `profile/collection/delete/${id}`,
      "POST",
      {},
      {
        toastSuccess: true,
        message: "عملیات موفقیت آمیز بود",
      }
    );
  }

  /**
   *
   * @returns
   */
  static GetSingleCollection() {
    return UserRequest.sendRequest("profile/collection/get", "GET", {}, {});
  }

  /**
   *
   * @returns get defautl and custom backpack items list
   */
  static GetTodosList() {
    return UserRequest.sendRequest("profile/todoList", "GET", {}, {});
  }

  static CreateCustomTodo(data) {
    return UserRequest.sendRequest(
      "profile/todoList/todoCustom/store",
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "موفق بود",
      }
    );
  }

  static AddTodoItem(data) {
    return UserRequest.sendRequest(
      `profile/todoList/todoCustom/store`,
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "ساخته شد",
      }
    );
  }

  /**
   *
   * @param {number} todo_id id of todo item which want to rename
   * @param {string} name new todo name
   * @returns custom todo updated
   */
  static RenameCustomTodoItem(todo_id, name) {
    return UserRequest.sendRequest(
      `/profile/todoList/todoCustom/rename/${todo_id}?name=${name}`,
      "POST",
      {},
      {}
    );
  }

  static DeleteTodoListItem(todo_id) {
    return UserRequest.sendRequest(
      `profile/todoList/todoCustom/destroy/${todo_id}`,
      "POST",
      {},
      {
        toastSuccess: true,
        message: "deleted",
      }
    );
  }

  /**
   *
   * @param {number} todo_id id of todo item which is want to check
   * @returns todo items checked
   */
  static CheckedTodoListItems(todo_id) {
    return this.sendRequest(
      `profile/todoList/store/${todo_id}`,
      "POST",
      {},
      {}
    );
  }

  /**
   *
   * @param {number} place_id id of place which want to submit comment
   * @param {string} comment text of comment which want to submit
   * @returns comment submite
   */
  static AddPlaceComment(place_id, comment) {
    return this.sendRequest(
      `profile/place/comment/${place_id}?comment=${comment}`,
      "POST",
      {},
      { toastSuccess: true, message: "کامنت با موفقیت ذخیره شد" }
    );
  }

  /**
   *
   * @param {number} blog_id id of blog which want to store comment
   * @param {object} data comment
   * @returns comment store
   */
  static AddBlogComment(blog_id, data) {
    return this.sendRequest(
      `profile/blog/comment/${blog_id}`,
      "POST",
      { data },
      {
        toastSuccess: true,
        message: "کامنت با موفقیت ذخیره شد",
      }
    );
  }

  /**
   *
   * @returns get notification lists
   */
  static Notifications() {
    return this.sendRequest("profile/notification", "GET", {}, {});
  }

  /**
   *
   * @param {number} notif_id id of notification which want to set seen status
   * @returns notification seen
   */
  static SeenNotification(notif_id) {
    return this.sendRequest(`profile/notification/${notif_id}`, "GET", {}, {});
  }
}
